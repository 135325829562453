<template>
  <v-skeleton-loader :loading="status.reading" type="article">
    <v-sheet v-bind="propsCompSheet" data-cy="users-edit">
      <div class="mb-8">{{ $t("users.deleteConfirmation") }}</div>

      <v-container class="pa-0 mb-8">
        <v-row>
          <v-col cols="12" md="2">
            <div class="label-show">{{ $t("common.userName") }}:</div>
          </v-col>
          <v-col cols="12" md="10">
            <div>{{ user.userName }}</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="2">
            <div class="label-show">{{ $t("common.name") }}:</div>
          </v-col>
          <v-col cols="12" md="10">
            <div>{{ user.name }}</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="2">
            <div class="label-show">{{ $t("common.surnames") }}:</div>
          </v-col>
          <v-col cols="12" md="10">
            <div>{{ user.surnames }}</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="2">
            <div class="label-show">{{ $t("common.role") }}:</div>
          </v-col>
          <v-col cols="12" md="10">
            <div>
              <UserRoleFieldRO :roleValue="user.role"></UserRoleFieldRO>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="authCan('see.admin-options')">
          <v-col cols="12" md="2">
            <div class="label-show">{{ $t("common.email") }}:</div>
          </v-col>
          <v-col cols="12" md="10">
            <div>{{ user.email }}</div>
          </v-col>
        </v-row>
      </v-container>

      <div class="d-flex">
        <v-btn
          v-bind="propsButton"
          :loading="status.loading"
          :to="{ name: 'users-index' }"
          data-cy="button-cancel"
          >{{ $t("common.cancel") }}</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="
            !authCanByUserId('editOwn.users', this.objectId) &&
            authCan('editOthers.users')
          "
          v-bind="propsButtonTrash"
          :loading="status.loading"
          :disabled="!formRemove"
          @click="clickRemove"
          data-cy="button-remove"
          >{{ $t("common.yes") }}</v-btn
        >
      </div>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "UsersEdit",
  mixins: [Permissions, ComponentStatus],
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formRemove() {
      // if (this.isAdmin) {
      //   return true;
      // }
      return true;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      if (this.firstFormClick) {
        return true;
      }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    // 2) When the user id changes we use the store getter to "get" the
    // object that has (or the temporary one that will have) the user data.
    // Note that this is different than the store action used to read the
    // actual data from the backend, they both work "in parallel".
    user() {
      return this.$store.getters["users/readById"](this.objectId);
    },
  },
  watch: {
    // 3) If the user id changes and we have not edit any field we trigger
    // the store action to load the data. Note that this is different than
    // the getter used to display the data, they both work "in parallel".
    objectId: {
      immediate: true,
      handler: function () {
        this.setStatusReading();
        this.editUserId = this.objectId;
        this.$store
          .dispatch("users/readById", this.objectId)
          .then((/* result */) => {
            this.setStatusReadSuccess();
          })
          .catch((/* error */) => {
            this.setStatusReadError();
          });
      },
    },
  },
  created() {},
  methods: {
    clickRemove() {
      this.setStatusDeleting();
      this.$store
        .dispatch("users/delete", this.editUserId)
        .then((/* result */) => {
          this.setStatusDeleteSuccess();
          this.$store.commit("status/showSuccess");
          this.$router.push({ name: "users-index" });
        })
        .catch((/* error */) => {
          this.setStatusDeleteError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
